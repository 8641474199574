import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';
import { P } from '../components/typography';
import Container from '../components/container';
import Section from '../components/section';
import { fluidImageType } from '../helper/prop-types';

const ImprintPage = ({ data }) => (
    <Layout
        title="Alle Informationen über Die Original Lechtaler-Website"
        description="In unserem Impressum erfährst du alles über unsere Website und die bestehenden Handlungsgrundlagen."
    >
        <Hero image={data.file.childImageSharp.fluid} title="Impressum" />
        <Section>
            <Container maxWidth="small">
                <P>
                    <strong>
                        Wenn du Fragen zu unseren Produkten oder unserem Unternehmen hast,
                        kontaktiere uns telefonisch oder per Mail.
                    </strong>
                </P>

                <P>
                    Justus-von-Liebig-Straße 48
                    <br />
                    86899 Landsberg am Lech
                    <br />
                    Germany
                </P>

                <P gap="xl">
                    <strong> Zentrale:</strong>
                    <br />
                    Telefon: +49 8191/105-0
                    <br />
                    Fax: +49 8191/105-271
                    <br />
                    <a href="mailto:info@die-original-lechtaler.de">
                        info@die-original-lechtaler.de
                    </a>
                </P>
                <P gap="xl">
                    Es gelten die Allgemeinen Geschäftsbedingungen der zur Mühlen Gruppe. Unter
                    folgendem Link gelangen Sie zur Webseite der zur Mühlen Gruppe auf der die
                    aktuellen AGB’s zum Download bereit stehen:
                    <br />
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://zurmuehlengruppe.de/de/agb/"
                    >
                        https://zurmuehlengruppe.de/de/agb/
                    </a>
                </P>
                <hr />
                <p>
                    <strong>Geschäftsadresse:</strong>
                    <br />
                    zur Mühlen ApS & Co. KG
                    <br />
                    Gewerbestraße 1<br />
                    24860 Böklund
                    <br />
                </p>
                <br />
                <p>
                    Telefon: +49 (0)4623 77–0
                    <br />
                    Fax: +49 (0)4623 77–310
                    <br />
                    E-Mail: info@zurmuehlen-group.com
                    <br />
                </p>
                <br />
                <p>
                    <strong>Vertretungsberechtigte:</strong>
                    <br />
                    Maximilian Tönnies, Geschäftsführer
                    <br />
                    Herr Axel Knau, Geschäftsführer
                    <br />
                </p>
                <br />
                <p>
                    <strong>Handelsregister:</strong>
                    <br />
                    Registergericht Flensburg HRA 7809 FL
                    <br />
                    USt-IdNr.: DE 242366780
                    <br />
                </p>
                <br />
                <p>
                    <strong>Komplementärin:</strong>
                    <br />
                    zur Mühlen ApS, Herning, Dänemark
                </p>
                <br />
                <p>
                    <strong>Handelsregister:</strong>
                    <br />
                    Erhvervsstyrelsen, Kopenhagen CVR-Nr.: 31869021
                </p>
                <br />
                <p>
                    <strong>Verantwortlich:</strong>
                    <br />
                    Herr Axel Knau
                </p>
                <br />
            </Container>
        </Section>
    </Layout>
);

ImprintPage.propTypes = {
    data: PropTypes.shape({
        file: fluidImageType,
    }).isRequired,
};

export default ImprintPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "keyvisual/keyvisual-biergarden.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    }
`;
